<template>
    <div class="mt-16">
      <img src="/happy.svg" alt="" width="48">
      <p class="mt-8">
        <strong>{{ready_title}}</strong>.<br/>
        {{ready_desc}}
      </p>
  </div>

</template>

<script>
export default {
  name: "Ready",
  data:()=>({
    ready_title: "¡LISTO!",
    ready_desc: "Ticksy ya puede comenzar a recibir pedidos."
  }),
}
</script>